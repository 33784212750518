<template>
  <InsetPanel
      :title="t('title')"
      class="logout-view">
    <Paragraph
        v-if="!errors || errors.length === 0">
      {{ t('message') }}
    </Paragraph>
    <Message
        v-else
        type="error">
      <ul>
        <li
            v-for="error in errors"
            :key="error">
          {{ error }}
        </li>
      </ul>
    </Message>
  </InsetPanel>
</template>
<i18n>
{
  "nl": {
    "title": "Uitloggen",
    "message": "Je bent uitgelogd",
    "error": "Uitloggen mislukt"
  },
  "en": {
    "title": "Sign out",
    "message": "You are signed out",
    "error": "Sign out failed"
  }
}
</i18n>
<script>

import {
  onMounted,
  ref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useHead } from '@unhead/vue';
import { useRouter } from 'vue-router';
import Message from '../../components/messages/Message.vue';
import useSession from '../../session/session.js';
import Paragraph from '../../components/typography/Paragraph.vue';
import InsetPanel from '../../components/panels/InsetPanel.vue';

export default {
  components: {
    InsetPanel,
    Paragraph,
    Message,
  },
  setup() {
    const { t, locale } = useI18n();
    const { user, logout, stopImpersonate } = useSession();
    const errors = ref(null);
    const router = useRouter();

    useHead({ title: t('title') });

    onMounted(async () => {
      if (!user.value) {
        errors.value = [];
        return; // We are not logged in
      }
      try {
        if (user.value.impersonator) {
          const { person } = user.value;
          await stopImpersonate();
          await router.push({
            name: `${locale.value}:admin:person`,
            params: { person: person.slug },
          });
        } else {
          await logout();
        }
        errors.value = [];
      } catch (e) {
        console.error(e);
        errors.value = [
          t('error'),
          `${e}`,
        ];

      }
    });

    return { t, errors };
  },

};
</script>
<style>
.logout-view {
  max-width: 25rem;
}
</style>
