<template>
  <InsetPanel
      class="reset-password-view"
      :title="t('title')">
    <ActionValidatorForm
        name="reset"
        :validator="validator"
        class="reset-password-view__form"
        :submit="submit">
      <template #submit-label>
        {{ t('submit') }}
      </template>

      <PasswordFieldSet v-model="password" />
    </ActionValidatorForm>
  </InsetPanel>
</template>
<i18n>
{
  "nl": {
    "title": "Wachtwoord opnieuw instellen",
    "submit": "Stel nieuw wachtwoord in"
  },
  "en": {
    "title": "Reset password",
    "submit": "Reset password"
  }
}
</i18n>
<script>
import { ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { useHead } from '@unhead/vue';
import { useI18n } from 'vue-i18n';
import {
  useRoute,
  useRouter,
} from 'vue-router';
import useSession from '../../session/session.js';
import InsetPanel from '../../components/panels/InsetPanel.vue';
import ActionValidatorForm from '../../components/forms/ActionValidatorForm.vue';
import PasswordFieldSet from '../../components/fieldsets/PasswordFieldSet.vue';

export default {
  components: {
    PasswordFieldSet,
    ActionValidatorForm,
    InsetPanel,
  },

  setup() {
    const password = ref('');

    const { user, resetPassword } = useSession();

    const { t, locale } = useI18n();
    const route = useRoute();
    const router = useRouter();

    const validator = useVuelidate({}, {}, { $rewardEarly: true });

    useHead({
      title: t('title'),
    });

    return {
      t,
      locale,
      validator,

      password,

      user,

      async submit() {
        await resetPassword(
          route.params.uid,
          route.params.token,
          password.value,
        );
        await router.push({ name: `${locale.value}:login` });
      },
    };
  },
};
</script>

<style>
.reset-password-view {
  width: 100%;
  max-width: 25rem;
}
</style>
