<template>
  <InsetPanel
      class="request-password-reset-view"
      :title="t('title')">
    <Paragraph
        v-if="success === null">
      {{ t('intro') }}
    </Paragraph>
    <Message
        v-else
        class="request-password-reset-view__message">
      {{ t('success') }}
    </Message>

    <ActionValidatorForm
        name="reset"
        :validator="validator"
        class="request-password-reset-view__form"
        :submit="submit">
      <template #submit-label>
        {{ t('submit') }}
      </template>
      <CSRFToken />

      <FieldSet>
        <Field
            :label="t('field.email.label')"
            :validator="validator.email"
            name="email">
          <template #default="{ id, fieldName }">
            <TextInput
                :id="id"
                ref="emailInput"
                v-model.trim="validator.email.$model"
                :validator="validator.email"
                autofocus="autofocus"
                type="email"
                :name="fieldName" />
          </template>
          <template #error>
            <li v-if="validator.email.required.$invalid">
              {{ t('field.email.required') }}
            </li>
            <li v-if="validator.email.email.$invalid">
              {{ t('field.email.invalid') }}
            </li>
          </template>
        </Field>
      </FieldSet>
    </ActionValidatorForm>

    <SectionPanel
        v-if="!user"
        :title="t('noEmail')">
      <ActionPane>
        <Paragraph>
          {{ t('noEmailExplanation') }}
        </Paragraph>

        <template #buttons>
          <IconButton
              :to="{name: `${locale}:contact`}"
              type="secondary">
            {{ t('contact') }}
          </IconButton>
        </template>
      </ActionPane>
    </SectionPanel>
  </InsetPanel>
</template>
<i18n>
{
  "nl": {
    "intro": "Als je jouw wachtwoord niet meer hebt of weet, kun je een instructie naar jouw e-mailadres verzonden krijgen om jouw wachtwoord opnieuw in te stellen.",
    "noEmail": "E-mailadres kwijt?",
    "noEmailExplanation": "Het kan zijn dat je gewisseld bent van internet-provider of niet meer weet onder welk e-mailadres je jezelf geregistreerd heeft. Neem in dit geval contact op.",
    "submit": "Vraag aan",
    "success": "Er wordt een e-mail verstuurd naar het door jou opgegeven adres.",
    "title": "Wachtwoord opnieuw instellen",
    "contact": "Contact opnemen",
    "field": {
      "email": {
        "invalid": "Vul een e-mailadres in",
        "label": "E-mailadres",
        "required": "Vul een e-mailadres in"
      }
    }
  },
  "en": {
    "intro": "We can send you instructions to reset your password if you lost or forgot it",
    "noEmail": "Lost your e-mail address?",
    "noEmailExplanation": "If you no longer control the e-mail address you registered with, please contact us.",
    "submit": "Request",
    "success": "An e-mail will be sent to the given address.",
    "title": "Reset password",
    "contact": "Contact",
    "field": {
      "email": {
        "invalid": "Please fill in an e-mail address",
        "label": "E-mail address",
        "required": "Please fill in an e-mail address"
      }
    }
  }
}
</i18n>
<script>
import {
  email as emailValid,
  required,
} from '@vuelidate/validators';

import {
  onMounted,
  ref,
} from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { useHead } from '@unhead/vue';
import { useI18n } from 'vue-i18n';
import Field from '../../components/fields/FormField.vue';
import TextInput from '../../components/widgets/forms/TextInput.vue';
import FieldSet from '../../components/fieldsets/FieldSet.vue';
import IconButton from '../../components/buttons/IconButton.vue';
import Message from '../../components/messages/Message.vue';
import SectionPanel from '../../components/panels/SectionPanel.vue';
import useSession from '../../session/session.js';
import CSRFToken from '../../components/widgets/forms/CSRFToken.vue';
import Paragraph from '../../components/typography/Paragraph.vue';
import InsetPanel from '../../components/panels/InsetPanel.vue';
import ActionPane from '../../components/panels/ActionPane.vue';
import ActionValidatorForm from '../../components/forms/ActionValidatorForm.vue';

export default {
  components: {
    ActionValidatorForm,
    ActionPane,
    InsetPanel,
    Paragraph,
    CSRFToken,
    IconButton,
    SectionPanel,
    FieldSet,
    TextInput,
    Field,
    Message,
  },

  setup() {
    const email = ref('');
    const emailInput = ref(null);
    const success = ref(null);

    const { user, requestPasswordReset } = useSession();

    const { t, locale } = useI18n();

    const rules = {
      email: {
        email: emailValid,
        required,
        $lazy: true,
      },
    };

    const validator = useVuelidate(rules, { email }, { $rewardEarly: true });

    onMounted(() => {
      emailInput.value?.$el.focus();
    });

    useHead({
      title: t('title'),
    });

    return {
      t,
      locale,
      validator,
      email,
      emailInput,

      user,
      success,

      async submit() {
        try {
          await requestPasswordReset(email);
          // TODO: failure messages

          success.value = true;
        } catch (e) {
          success.value = false;
        }
      },
    };
  },
};
</script>

<style>
.request-password-reset-view {
  width: 100%;
  max-width: 25rem;
}
</style>
